import React from 'react';
import Seo from 'components/seo';
import ErrorSec from 'containers/Error';
import { ResetCSS } from 'common/assets/css/style';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import Footer from 'containers/AgencyModern/Footer';
import Thanksgiving from 'containers/SaasModern/Thanksgiving/index.js';

const NotFoundPage = () => (
  <ThemeProvider theme={saasModernTheme}>
    <>
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
            {/* <Thanksgiving /> */}
          </DrawerProvider>
        </Sticky>
        <Seo title="404: Not found" />
        <ResetCSS />
        <ErrorSec></ErrorSec>
        <Footer />
      </ContentWrapper>
    </>
  </ThemeProvider>
);

export default NotFoundPage;
